import { SermonModule } from "../Modules/SermonModule";

window.addEventListener('load', function () {
    SermonModule.SetupSermonInit();
});

document.addEventListener('widgetLoaded', function (args) {
    DEBUG && console.info(args);
    if (args.detail.model.Name === "SermonDetail") {
        DEBUG && console.info('**************************************************');
        DEBUG && console.info('=== Widget Reload Event ===')
        DEBUG && console.info('Element: ', args.detail.element);
        DEBUG && console.info('**************************************************');
        SermonModule.Init();
    }
});